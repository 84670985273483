import i18n from "./../i18n"

/* 회원 로그인 */
export const login = (state, payload) => {
  state.lafia_user.is_logined = true
  state.lafia_user.user_id = payload.user_id
  state.lafia_user.name = payload.name
  state.lafia_user.phone = payload.phone
  state.lafia_user.email = payload.email
  state.lafia_user.profile_image = payload.profile_image
  state.lafia_user.type = payload.type
  state.lafia_user.status = payload.status
  state.lafia_user.created = payload.created
}

// 회원 로그아웃
export const logout = (state) => {
  state.lafia_user.is_logined = false
  state.lafia_user.user_id = ""
  state.lafia_user.name = ""
  state.lafia_user.phone = ""
  state.lafia_user.email = ""
  state.lafia_user.profile_image = ""
  state.lafia_user.type = ""
  state.lafia_user.status = ""
  state.lafia_user.created = ""
}

// 사용자 프로필 수정하기
export const update_profile_image = (state, payload) => {
  state.lafia_user.profile_image = payload
}

// 관리자 로그인
export const admin_login = (state, payload) => {
  state.lafia_admin.is_logined = true
  state.lafia_admin.type = payload.type
  state.lafia_admin.name = payload.name
  state.lafia_admin.user_id = payload.user_id
}

// 관리자 로그아웃
export const admin_logout = (state) => {
  state.lafia_admin.is_logined = false
  state.lafia_admin.type = ""
  state.lafia_admin.name = ""
  state.lafia_admin.user_id = ""
}

export const ADD_TEMP_SNS_USER = (state, payload) => {
  state.temp_sns_user = payload
}

export const DELETE_TEMP_SNS_USER = (state) => {
  state.temp_sns_user = {}
}

export const ADD_PROMOTION_CODE = (state, payload) => {
  state.promotionCode = payload
}

export const DELETE_PROMOTION_CODE = (state) => {
  state.promotionCode = undefined
}

export const SET_INAPPBROWER_STATE = (state, payload) => {
  state.isShowinappBrowserWarning = payload
}

export const SET_LANG = (state, payload) => {
  const supportLang = ["ko", "en", "jp"]
  state.lang = supportLang.includes(payload) ? payload : "ko"
  i18n.locale = state.lang
}

export const SET_HOME_TAB = (state, payload) => {
  state.home_selected_tab = payload
}

export const SET_APP_RELOAD_TIME = (state, payload) => {
  state.app_reload_time = payload
}

export const SET_DEFAULT_PRICE = (state, payload) => {
  state.default_price = payload
}

export const SET_DEFAULT_PACKAGE_PRICE = (state, payload) => {
  state.default_package_price = payload
}

export const SET_CONCEPTS = (state, payload) => {
  state.concepts = payload
}

export const SET_OPERATION_TIME = (state, payload) => {
  state.operation_time = payload
}
