function imageUrl(url, timestamp = null) {
  if (!url.startsWith("http")) {
    let imageHost
    switch (process.env.NODE_ENV) {
      case "local":
        imageHost = "http://dev.sela-castle.com"
        break
      default:
        imageHost = ""
    }
    url = imageHost + url
  }
  if (timestamp) {
    return updateTimeStamp(url, timestamp)
  }
  return url
}

function profileImageUrl(url) {
  if (!url.startsWith("http")) {
    return imageUrl("/upload/user/profile_image/" + url)
  }
  return url
}

function updateTimeStamp(url, timestamp = null) {
  url = url.split("?")[0]
  return url + "?t=" + (timestamp || new Date().getTime())
}

export default {
  imageUrl,
  profileImageUrl,
  updateTimeStamp,
}
