import dayjs from "dayjs"
import * as dayjsExtend from "dayjs/plugin/isSameOrAfter"
import * as isBetween from "dayjs/plugin/isBetween"
import * as customParseFormat from "dayjs/plugin/customParseFormat"

export default {
  install(Vue) {
    dayjs.extend(dayjsExtend)
    dayjs.extend(isBetween)
    dayjs.extend(customParseFormat)

    Vue.dayjs = dayjs
    Object.defineProperty(Vue.prototype, "dayjs", {
      get() {
        return dayjs
      },
    })
  },
}
