const methods = {
  getTDaysLater(date, days, short = false) {
    let d = new Date(date)
    d.setDate(d.getDate() + days)
    if (short) {
      return d
        .toLocaleDateString("ko-KR", {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\./g, ".")
        .replace(/\s+/g, "")
        .replace(/\.$/, "")
    }
    return d.toLocaleDateString()
  },
}

export default methods
