export const kStorageUrl = "https://front.sela-castle.com"

export function getLayerByName(layer, name) {
  if (layer.name === name) {
    return layer
  }

  if (layer.children) {
    for (const child of layer.children) {
      const found = getLayerByName(child, name)
      if (found) {
        return found
      }
    }
  }

  return null
}

export function traverseLayer(layer, callback) {
  callback(layer)

  if (layer.children) {
    for (const child of layer.children) {
      traverseLayer(child, callback)
    }
  }
}

export function getLayersRect(layers) {
  if (layers.length === 0) {
    return { left: 0, top: 0, right: 0, bottom: 0 }
  }

  let left = Infinity
  let top = Infinity
  let right = -Infinity
  let bottom = -Infinity

  for (const layer of layers) {
    left = Math.min(left, layer.left)
    top = Math.min(top, layer.top)
    right = Math.max(right, layer.left + layer.width)
    bottom = Math.max(bottom, layer.top + layer.height)
  }

  return { left, top, right, bottom }
}

export function convertSupprotBlendMode(blendMode) {
  if (!blendMode) {
    return "normal"
  }

  switch (blendMode) {
    case "linear-burn":
    case "linear burn":
      return "color-burn"

    case "lighter-color":
    case "lighter color":
      return "lighten"

    case "linear dodge":
    case "linear-dodge":
      return "screen"

    default:
      return blendMode
  }
}

function convertLayerBlendMode(layer) {
  layer.blendMode = convertSupprotBlendMode(layer.blendMode?.replace(" ", "-"))
}

export async function fetchPSDLayer(editItem) {
  const document = await fetch(`${kStorageUrl}/composite/${editItem.filename.replace(".jpg", ".json")}?t=${editItem.updated}`).then((res) => res.json())
  traverseLayer(document, (layer) => {
    if (!layer.children) {
      if (layer.name == "인물") {
        layer.src = `${kStorageUrl}/composite/${editItem.filename.replace(".jpg", "_subject.png")}`
      } else if (layer.name == "그림자") {
        layer.src = `${kStorageUrl}/composite/${editItem.filename.replace(".jpg", "_shadow.png")}`
      } else {
        const title = editItem.title.replace(/\s/g, "")
        layer.src = `${kStorageUrl}/template/${title}/${layer.id}.png`
      }
      if (layer.flipX === undefined) {
        layer.flipX = false
      }
    }
  })
  return optimizePSDLayer(document)
}

export async function fetchConceptPSDLayer(editItem) {
  const title = editItem.title.replace(/\s/g, "")
  const document = await fetch(`${kStorageUrl}/template/${title}/template.json`).then((res) => res.json())
  traverseLayer(document, (layer) => {
    if (!layer.children) {
      layer.src = `${kStorageUrl}/template/${title}/${layer.id}.png`
    }
  })
  return optimizePSDLayer(document)
}

function optimizePSDLayer(document) {
  let index = 0
  traverseLayer(document, (layer) => {
    if (!layer.children) {
      if (layer.width == 0 || layer.height == 0) {
        layer.hidden = true
      }
    }
    layer.index = index++
    convertLayerBlendMode(layer)
  })
  document.isRoot = true
  document.name = "레이어"
  return document
}

export async function newImageFileFromPSDLayer(layer, filename) {
  const canvas = document.createElement("canvas")
  canvas.width = layer.width
  canvas.height = layer.height
  const ctx = canvas.getContext("2d")

  const _flipXImage = async (image) => {
    const flippedCanvas = document.createElement("canvas")
    flippedCanvas.width = image.width
    flippedCanvas.height = image.height
    const flippedCtx = flippedCanvas.getContext("2d")
    flippedCtx.translate(image.width, 0)
    flippedCtx.scale(-1, 1)
    flippedCtx.drawImage(image, 0, 0)
    return flippedCanvas
  }

  const _loadImage = async (layer) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = layer.src
      image.setAttribute("crossorigin", "anonymous")
      image.onload = () => {
        if (layer.flipX) {
          resolve(_flipXImage(image))
        } else {
          resolve(image)
        }
      }
      image.onerror = () => {
        console.error(`Failed to load image: ${layer.src}`)
        resolve(null)
      }
    })
  }

  const _renderLayer = async (layer) => {
    if (layer.children) {
      for (const child of layer.children) {
        await _renderLayer(child)
      }
    } else if (layer.src && !layer.hidden && layer.id > 0) {
      const image = await _loadImage(layer)
      if (image == null) {
        return
      }
      ctx.save()
      ctx.globalAlpha = layer.opacity
      ctx.globalCompositeOperation = layer.blendMode || "normal"
      ctx.drawImage(image, layer.left, layer.top, layer.width, layer.height)
      ctx.restore()
    }
  }

  await _renderLayer(layer)

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(new File([blob], filename, { type: "image/jpeg" }))
    }, "image/jpeg")
  })
}

export function getLayerImageCount(document) {
  let count = 0
  traverseLayer(document, (layer) => {
    if (!layer.children) {
      if (layer.src) {
        count++
      }
    }
  })
  return count
}
