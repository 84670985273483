import Vue from "vue"
import VueI18n from "vue-i18n"
import en from "./locales/en.json"
import ko from "./locales/ko.json"
import jp from "./locales/jp.json"

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "ko",
  messages: { en, ko, jp },
  silentTranslationWarn: process.env.NODE_ENV === "production",
})

export default i18n
