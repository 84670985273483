import axios from "@/api/restApi"

export default {
  async checkDuplicateEmail(email) {
    const res = await axios.post("/api/user/select/specific/checkDuplcate", {
      params: {
        user_id: email,
      },
    })
    return res.data[0]
  },

  async findUserByEmail(email) {
    const res = await axios.post("/api/user/select/specific/email", {
      params: {
        email,
      },
    })
    return res.data[0]
  },

  async doUserInsert(user) {
    return await axios.post("/api/user/insert", {
      params: user,
    })
  },

  async updateLastLoginAt(user_id) {
    return await axios.post("/api/user/update/lastLoginAt", {
      params: { user_id },
    })
  },

  async updateSnsUserInfo(snsUser) {
    return await axios.post("/api/user/update/updateSnsUserInfo", {
      params: snsUser,
    })
  },

  async updateQrCode(userId, qrCode) {
    return await axios.post("/api/qrcode/update/user_id", {
      params: {
        user_id: userId,
        qrcode: qrCode,
      },
    })
  },

  async updatePayQrcode(userId, qrCode) {
    return await axios.post("/api/qrcode/pay/update/user_id", {
      user_id: userId,
      qrcode: qrCode,
    })
  },

  async loginUserIdPassword(user_id, password) {
    return await axios.post("/api/user/select/login", {
      params: {
        user_id,
        password,
      },
    })
  },

  async checkBackupPassword(user_id, password) {
    return await axios.post("/api/user/select/login/backup_password", {
      params: {
        user_id,
        password,
      },
    })
  },

  async getUser(user_id) {
    const res = await axios.post("/api/user/select/specific/user_id", {
      params: {
        user_id,
      },
    })
    return res.data[0]
  },

  /**
   *
   * @param {*} user_id
   * @param {*} password
   * @returns  true, false : 비밀번호 일치 여부
   */
  async checkCurrentPassword(user_id, password) {
    const res = await axios.post("/api/user/select/checkPassword", {
      params: {
        user_id,
        password,
      },
    })
    return res.data.result
  },

  async updateUserPhoneNumber(params) {
    const res = await axios.post("/api/user/update/phoneNumber", {
      params,
    })
    return res.data.result
  },

  async updatePassword(user_id, password_next) {
    return await axios.post("/api/user/update/password", {
      params: {
        user_id,
        password_next,
      },
    })
  },

  /**
   *
   * @param {*} name
   * @param {*} phone
   * @returns success: { user_id } faile : { error : { message }}
   */
  async findId(name, phone) {
    const res = await axios.post("/api/user/select/id", {
      params: {
        name,
        phone,
      },
    })
    return res.data
  },
}
