import Vue from "vue"
import App from "./App.vue"
import Router from "vue-router"
import store from "./store/index"
import router from "./routes.js"
import vuetify from "./plugins/vuetify"
import lodash from "lodash"
import "@babel/polyfill"
import Chartkick from "vue-chartkick"
import Chart from "chart.js"
import { DateTime } from "luxon"
import axios from "@/api/restApi"
import i18n from "./i18n"

// import VueLuxon from "vue-luxon";

if (process.env.VUE_APP_CONSOLE_ENABLE === "false") {
  // production에서만 사용할 수 없도록
  console = window.console || {}
  console.log = function no_console() {} // console log 막기
  console.warn = function no_console() {} // console warning 막기
  console.error = function () {} // console error 막기
}

// Vue2는 Composition API 없으면 이 방식으로 this.dayjs 형식으로 사용 가능
import dayjsGlobal from "./plugins/dayjs.js"
Vue.use(dayjsGlobal)

// 링크 복사 ($copytext)
import VueClipboard from "vue-clipboard2"
Vue.use(VueClipboard)

import VueRx from "vue-rx"
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd.min"
import "vuejs-clipper/dist/vuejs-clipper.css"
Vue.use(VueRx)
Vue.use(VuejsClipper)

// SNS 로그인 > Google
import GSignInButton from "vue-google-signin-button"
Vue.use(GSignInButton)

import CustomMethods from "./methods"
Vue.use(CustomMethods)

// vue-carousel (약도)
import VueCarousel from "vue-carousel"
Vue.use(VueCarousel)

Vue.use(Router)
Vue.use(Chartkick.use(Chart))

import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-bootstrap.css"
Vue.use(VueToast, {
  position: "bottom",
})

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$DateTime = DateTime

export const eventBus = new Vue()

import VueGtag from "vue-gtag"
Vue.use(
  VueGtag,
  {
    config: "G-QF0KNT1WQE",
    params: {
      send_page_view: false,
    },
  },
  router
)

if (store.state.lang) {
  i18n.locale = store.state.lang
} else {
  const supportLang = ["ko", "en", "jp"]
  const naviLang = navigator.language.split("-")[0]
  store.state.lang = supportLang.includes(naviLang) ? naviLang : "en"
  i18n.locale = store.state.lang
}

store.dispatch("getPromotionPrice")
store.dispatch("loadOperationTime")
//store.dispatch("loadConcepts")

axios.interceptors.response.use(async (res) => {
  const newApiVersion = res.headers["x-version"]
  if (newApiVersion) {
    try {
      await store.dispatch("appVersionCheck", newApiVersion)
    } catch (e) {}
  }

  return res
})

new Vue({
  router,
  store,
  vuetify,
  lodash,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
