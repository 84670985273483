import Router from "vue-router"

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "Common" */ "./layouts/front/index.vue"),
    children: [
      // 결제 강제 취소시 화면
      {
        path: "/fail",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/toss/fail.vue"),
      },

      // TESTS
      {
        path: "tests",
        component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "/",
            component: () => import(/* webpackChunkName: "Common" */ "@/tests/index.vue"),
          },

          // 로켓웍스
          {
            path: "rocket-works",
            component: () => import(/* webpackChunkName: "Common" */ "@/tests/rocket-works/api.vue"),
          },
        ],
      },

      // BEST (메인)
      {
        path: "/",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/home.vue"),
      },

      // BEST (메인) (/index.html 대응)
      {
        path: "/index.html",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/home.vue"),
      },

      // 예약하기
      {
        path: "reservation",
        component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "category",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/reservation/category.vue"),
          },
          {
            path: "buy",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/reservation/buy/LafiaStudio.vue"),
          },
          {
            path: "package",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/reservation/buy/index.vue"),
          },
          {
            path: "package/pay",
            name: "packagePay",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/reservation/buy/PackagePay.vue"),
            props: true,
          },
          {
            path: "complete",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/reservation/complete.vue"),
          },
          // {
          //   path: "package/earlyDetail",
          //   name: "earlyDetail",
          //   component: () => import(/* webpackChunkName: "Common" */ "@/views/reservation/buy/EarlyProductDetail.vue"),
          //   props: true,
          // },
        ],
      },
      {
        // 캐슬 소개
        path: "castle",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/concept/sela_castle_introduce.vue"),
      },
      {
        path: "castle/:conceptId",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/concept/sela_concept_detail.vue"),
      },
      {
        path: "eventPage/:eventCode",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/event/index.vue"),
      },

      // 촬영컨셉
      {
        path: "concept",
        component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "lafia_castle",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/concept/lafia_castle.vue"),
          },
          {
            path: "solo_premium",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/concept/solo_premium.vue"),
          },
        ],
      },
      // 오시는 길
      {
        path: "/location",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/location.vue"),
      },

      // 마이페이지
      {
        path: "mypage",
        component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "home",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/home.vue"),
          },
          {
            path: "select",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/select.vue"),
          },
          {
            path: "setting",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/setting.vue"),
          },
          {
            path: "picDetail/:id",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/MyPictureDetail.vue"),
          },
          {
            path: "picDetail/:id/print",
            name: "printPicPay",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/MyPicturePrintReq.vue"),
            props: true,
          },
          {
            path: "picEdit/:id",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/MyPictureEdit.vue"),
          },
          {
            path: "print/complete",
            name: "printReqCompl",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/mypage/printReqComplete.vue"),
          },
        ],
      },

      // Auth
      {
        path: "auth",
        component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "login",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/Login.vue"),
          },
          {
            path: "logout",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/Logout.vue"),
          },
          {
            path: "join",
            component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
            children: [
              {
                path: "agree",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/join/agree.vue"),
              },
              {
                path: "form",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/join/form.vue"),
              },
              {
                path: "snsJoinForm",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/join/snsJoinForm.vue"),
              },
            ],
          },
          {
            path: "find",
            component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
            children: [
              // {
              //   path: "id",
              //   component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/find/id.vue"),
              // },
              {
                path: "password",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/find/password.vue"),
              },
              {
                path: "confirm",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/find/confirmUser.vue"),
              },
              {
                path: "success",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/find/foundedId.vue"),
              },
            ],
          },
          {
            path: "sns",
            component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),
            children: [
              {
                path: "google",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/sns/google.vue"),
              },
              {
                path: "naver",
                component: () => import(/* webpackChunkName: "Common" */ "@/views/auth/sns/naver.vue"),
              },
            ],
          },
        ],
      },
      // Policy
      {
        path: "policy",
        component: () => import(/* webpackChunkName: "Policy" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "rule",
            component: () => import(/* webpackChunkName: "Policy" */ "@/views/policy/Rule.vue"),
          },
          {
            path: "privacy",
            component: () => import(/* webpackChunkName: "Policy" */ "@/views/policy/Privacy.vue"),
          },
        ],
      },
    ],
  },

  // 관리자
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "Admin" */ "@/layouts/admin/index.vue"),
    children: [
      // Auth
      {
        path: "auth",
        component: () => import(/* webpackChunkName: "Admin" */ "@/layouts/empty.vue"),
        children: [
          {
            path: "login",
            component: () => import(/* webpackChunkName: "Admin" */ "@/views/admin/auth/login.vue"),
          },
          {
            path: "logout",
            component: () => import(/* webpackChunkName: "Admin" */ "@/views/admin/auth/logout.vue"),
          },
        ],
      },

      /* 메이커 */

      // 라피아캐슬 관리
      {
        path: "lafia_castle",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/lafia_castle.vue"),
      },
      {
        path: "lafia/reservation",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/castle/onsite-reservation.vue"),
      },
      {
        path: "castle/onsite",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/castle/onsite-processing.vue"),
      },
      // 컨셉 관리
      {
        path: "concept",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/concept.vue"),
      },

      // 회원 관리
      {
        path: "user",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/user.vue"),
      },
      // 결제내역 관리
      {
        path: "payments",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/payments/search.vue"),
      },

      // 해외 결제내역 관리
      {
        path: "payletter",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/payments/abroad.vue"),
      },

      // 프로모션 관리
      {
        path: "promotion",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/promotion.vue"),
      },
      {
        path: "event",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/event/index.vue"),
      },

      /* 관리자 */
      // HOME 관리
      {
        path: "home",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/home.vue"),
      },

      // LOCATION 관리
      {
        path: "location",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/location.vue"),
      },

      // 자주묻는질문 관리
      {
        path: "qna",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/qna.vue"),
      },

      // 이용약관 관리
      {
        path: "rule",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/rule.vue"),
      },

      // 개인정보처리방침 관리
      {
        path: "privacy",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/privacy.vue"),
      },

      // 매출 관리
      {
        path: "sale",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/sale.vue"),
      },
      // 통계 관리
      {
        path: "stats",
        component: () => import(/* webpackChunkName: "Common" */ "@/layouts/empty.vue"),

        children: [
          {
            path: "/",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/stats/sela-new.vue"),
          },
          {
            path: "old",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/stats/sela.vue"),
          },
          {
            path: "sale",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/stats/selaSales.vue"),
          },

          {
            path: "promotionSale",
            component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/stats/selaPromotionSale.vue"),
          },
        ],
      },

      // 직원 관리
      {
        path: "staff",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/staff.vue"),
      },

      // 캐슬 둘러보기 관리
      {
        path: "sela-castle",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/castle/introduce.vue"),
        children: [],
      },
      {
        path: "operationTime",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/admin/operation_time/index.vue"),
      },

      // {
      //   path: "early",
      //   component: () => import(/* webpackChunkName: "Admin" */ "@/views/admin/early/list.vue"),
      // },
    ],
  },

  // 토스페이먼츠
  {
    path: "/toss",
    component: () => import(/* webpackChunkName: "Common" */ "@/layouts/toss/index.vue"),
    children: [
      {
        path: "success",
        component: () => import(/* webpackChunkName: "Common" */ "@/views/toss/success.vue"),
      },
    ],
  },
  {
    path: "/payletter/success",
    component: () => import(/* webpackChunkName: "Common" */ "@/views/payletter/result.vue"),
  },
]

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  let title = ""

  if (to.path.startsWith("/admin")) {
    title = "관리자페이지"
  }

  document.title = title + (title == "" ? "" : " - ") + "라피아 스튜디오"
  next()
})

export default router
