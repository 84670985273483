function imageFullUrl(url) {
  if (!url.startsWith("http")) {
    const imageHost = process.env.NODE_ENV == "development" ? "https://sela-castle.com" : window.location.origin
    return imageHost + url
  }
  return url
}

const methods = {
  // 링크 하이라이트 및 이동 추가
  linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

    return replacedText
  },

  async shareLink(url) {
    if (navigator.share) {
      return navigator
        .share({
          url: url,
        })
        .then(() => {
          console.log("Image shared successfully.")
        })
        .catch((error) => {
          console.error("Error sharing image:", error)
        })
    } else {
      alert("Sharing is not supported on your browser.")
      return Promise.resolve()
    }
  },
}

export default methods
